@import "../variables-app";
/* ----------------------------------------------------------------------
	Base Colors
-------------------------------------------------------------------------*/

/*Colors*/
$color-master:                  #626262 !default;

$color-theme:                    $color-book-blue-light;
$color-body:                     #565656;

$color-primary:                  $color-book-blue-dark;
$color-secondary:                $color-book-pink;
$color-success:                  $color-success;
$color-info:                     $color-info;
$color-warning:                  $color-warning;
$color-danger:                   $color-danger;

$color-complete:                  #1abc9c;

$color-white:                    #ffffff; 
$color-black:                    #000000;

// Lighter colors
$color-light:					#F2F2F4;
$color-lighter:		lighten($color-light, 1.4%);

$color-lightest:	lighten($color-light, 3%);

// Darker colors 
$color-dark:					#444;
$color-dark:mix($color-master, #000, 45%) !default;
$color-darker:mix($color-master, #000, 27%) !default;
$color-darkest:mix($color-master, #000, 18%) !default;



$color-grey:                     $color-grey;

 
$grey:                           $grey;
$dark:                           $dark; 
$darker:                         $darker;
$dark-light:                     $dark-light;
$light:                          $light;
$dark-grey:                      $dark-grey;
$slate-grey:                     $slate-grey; 
$silver:                         $silver;

 
$paragraph-color:                $color-font;



 
/*Typography*/
$font-family-1:                  'Open Sans', 'Helvetica', 'Arial', sans-serif;
$font-family-2:                  'Montserrat', 'Helvetica', 'Arial', sans-serif;
$font-family-3:                  'Raleway', 'Helvetica', 'Arial', sans-serif;
$font-family-4:                  'Roboto', 'Helvetica', 'Arial', sans-serif;
$font-family-5:                  'Nunito', 'Helvetica', 'Arial', sans-serif;
$font-family-6:                  'Poppins', 'Helvetica', 'Arial', sans-serif;
$font-family-7:                  'Merriweather';


/*Font*/
$font-size:                      13px;  

$font-weight:                    300;

$menu-font:                      $font-family-3; 
 
 
/*Header*/
$header-height:                  80px;
$header-line-height:             80px; 

/*Side Panel*/
$side-panel-width:               300px; 

/*Margins*/
$default-margin:                    14px;

/*Transitions*/
$transition-ease:                   all 0.3s ease;
 

//utilities
$border-width:                      1px;
$border-color:                      #ececec;
$border-radius:                     5px;


$border-background-color:                  $color-secondary;


$font-size-icon:					24px;
 

//Buttons
$button-border-radius:				$border-radius;

//Forms

$form-background-color:				$color-lightest;
$form-border-width:                      $border-width;
$form-border-color:                     $border-color;
$form-border-radius:				$border-radius;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:              true !default;
$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;

// Define common padding and border radius sizes and more.

$hover-color:                 $color-theme;
$active-color:                $color-theme;
$active-bg-color:             $grey;


$box-shadow:                  0 0 25px 0 rgba(0, 0, 0, 0.08);

$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;

$border-width:                1px !default;
$border-color:                $border-color !default;

$border-radius:               5px !default;
$border-radius-lg:            8px !default;
$border-radius-sm:            10px !default;

$transition-base:             all .3s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;






$base-font-family: wf_segoe-ui_light,wf_segoe-ui_normal,"Helvetica Neue", Helvetica, Arial, sans-serif !default;

 

 
