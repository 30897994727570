/* ----------------------------------------------------------------------
    Clients Logo
-------------------------------------------------------------------------*/

.grid li {
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0.8;
}

.clients-carousel a {
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0.8;
  img {
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    opacity: 0.8;
  }
}

.parallax .clients-carousel a {
  opacity: 1;
}

.grid li:hover {
  opacity: 1;
  cursor: pointer;
}

.clients-carousel a:hover {
  opacity: 1;
  cursor: pointer;
  img {
    opacity: 1;
    cursor: pointer;
  }
}

.client-carousel .owl-item img, .clients-logo .owl-item img {
  padding: 20px;
}

.grid {
  padding: 0;
  list-style: none;
  overflow: hidden;
  li {
    float: left;
    position: relative;
    width: 20%;
    padding: 20px;
  }
  &.grid-2-columns {
    li, figure {
      width: 50%;
    }
  }
  &.grid-3-columns {
    li, figure {
      width: 33.333333%;
    }
  }
  &.grid-4-columns {
    li, figure {
      width: 25%;
    }
  }
  &.grid-5-columns {
    li, figure {
      width: 20%;
    }
  }
  &.grid-6-columns {
    li, figure {
      width: 16.6666666%;
    }
  }
  li {
    &:hover {
      cursor: pointer;
      background-color: #f8f8f8;
    }
    a, img {
      display: block;
      width: 75%;
      margin-left: auto;
      margin-right: auto;
    }
    a img {
      width: 100%;
    }
    &:before, &:after {
      content: '';
      position: absolute;
    }
    &:before {
      height: 100%;
      top: 0;
      left: -1px;
      border-left: 1px solid #eee;
    }
    &:after {
      width: 100%;
      height: 0;
      top: auto;
      left: 0;
      bottom: -1px;
      border-bottom: 1px solid #eee;
    }
  }
  &.clients-grey {
    border: 1px solid #ddd;
    li {
      background-color: #eee;
      &:before {
        border-left: 1px solid #ccc;
      }
      &:after {
        border-bottom: 1px solid #ccc;
      }
    }
  }
  &.clients-border {
    border-width: 10px;
  }
}

/*client options*/

/*carousel logo*/

.carousel-description-clients {
  .carousel {
    margin-top: 40px;
  }
  .owl-prev, .owl-next {
    margin-top: -40px !important;
  }
}


/*Clients Responsive li*/
body {
  &.device-sm .carousel-description-clients .owl-controls, &.device-xs .carousel-description-clients .owl-controls, &.device-xxs .carousel-description-clients .owl-controls {
    display: none;
  }
  &.device-sm .grid li, &.device-xs .grid li {
    width: 33.33%;
  }
  &.device-xxs .grid li {
    width: 50%;
  }
}
