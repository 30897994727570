$color-white: #fff;
$light-grey: #ccc;

/* ----------------------------------------------------------------
    FOOTER
-----------------------------------------------------------------*/

#footer {
    clear: both;
    position: relative;
    .logo {
        margin-bottom: 54px;
        margin-top: 12px;
        max-width: 80px;
    }
}
.footer-logo {
    border-right: 1px solid #333;
    margin-bottom: 20px;
    margin-right: 18px;
    padding-right: 16px;
}
#footer {
    font-size: 13px;
    
    &.background-colored .footer-logo {
        border-right: 1px solid rgba(255, 255, 255, 0.4);
    }
    &.background-grey .footer-logo {
        border-right: 1px solid #ccc;
    }
}
/*  
.copyright-content {
   
.copyright-links a {
    padding: 10px;
}
.copyright-text {
    font-size: 12px;
    padding-top: 10px;
    text-align: left;
    text-transform: uppercase;
} */

// Social Icons
#footer { 
 
    background-color: $color-lighter;
        border-top: 1px solid #e5e5e5;
 /*        &,
        .widget h4,
        a {
            color: #494949;
        } */
        span {
            color: #898989 ;
        }

        .widget ul {
            margin-bottom:0;
        }

        .widget-tags .tags a {
        border: 1px solid rgba(255, 255, 255, 0.2);
    }


.footer-content {
    padding: 60px 0 40px 0;
} 


        .copyright-content {
            background-color: #efefef;
            border-top: 1px solid #e5e5e5; 



 border-top: 1px solid #ddd;
    min-height: 80px;
    padding: 30px 0;



            .to-top {
                background-color: #fff;
                border: 1px solid #e5e5e5;
                border-radius: 3px;
                float: right;
                line-height: 24px;
                padding: 4px 9px;
                position: relative;
                &:hover {
                    opacity: 0.9;
                }
                &:hover,
                &:focus {
                    color: #333;
                }
            }
        }
        strong {
            color: #5a5a5a;
        }

    
    .post-thumbnail-list .post-thumbnail-entry .post-thumbnail-content a {
	font-size: 13px;
	font-weight: 500;
}
    
    .social-icons {
        &.center {
            float: none;
            display: inline-block;
        }
        &:not(.social-icons-colored) li a:hover {
            background-color: transparent;
        }
    }
    // Footer Dark Version 
    &.dark {
        background-color: #101010;
        border-top: 1px solid #222;
        &,
        a {
            color: #666;
        }
        .widget h4,
        span {
            color: #999 !important;
        }
        .copyright-content {
            border-top: 1px solid #222;
            min-height: 80px;
            padding: 30px 0;
            background-color: #1a1a1a;
        }

        .post-thumbnail-list .post-thumbnail-entry {
                border-bottom: 1px solid #222;
        }

        .social-icons-colored a {
            color:$color-white;
        }
    }
    &.footer-sticky.footer-sticky-active {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 0;
        width: 100%;
    }
}
