


.alert {    

        color: $color-white;
        padding: 0.85rem 1.5rem;


        span[data-notify~="title"] {
            display: block;
            font-weight: bold;
        }
    
        span.fas {
            font-size: $font-size-icon;
            float: left;
            padding-top: 10px; 
            min-width: 40px;
            min-height: 40px; 
            }
        .close  {
            color: $color-white;
            font-size: $font-size-icon;
            margin-right: -4px;
        

            span { 
                display: none;     
            }
        } 

        &.alert-primary {
            background-color: $color-primary;
            border-color: darken($color-primary, 5%);
        }
        &.alert-secondary {
            background-color: $color-secondary;
            border-color: darken($color-secondary, 5%);
        }  
        &.alert-success {
            background-color: $color-success;
            border-color: darken($color-success, 5%);
        }
        &.alert-info {
            background-color: $color-info;
            border-color: darken($color-info, 5%);
        }
        &.alert-danger {
            background-color: $color-danger;
            border-color: darken($color-danger, 5%);
        } 

        &.alert-warning {
            background-color: $color-warning;
            border-color: darken($color-warning, 5%);
        }

        &.alert-light {
            color: $color-dark;
            background-color: $color-light;
            border-color: darken($color-light, 2%);
            .alert-link,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
            color:$color-dark;
            }
        }
        &.alert-dark {
            background-color: $color-dark;
            border-color: darken($color-dark, 5%);
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,         
        .alert-link {
            color:$color-white;
        }


        [data-notify="progressbar"] {
            height: 2px !important;
            margin-top: 8px;
        }
 
    }


 