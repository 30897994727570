/* ----------------------------------------------------------------------
TIMELINE
-------------------------------------------------------------------------*/

.timeline {
  margin-bottom: 60px;
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline-circles {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  background-color: #ccc;
  bottom: 0;
  content: " ";
  left: 50%;
  margin-left: -1.5px;
  position: absolute;
  top: 0;
  width: 4px;
}

.timeline-circles {
  &:before, &:after {
    background-color: #ccc;
    border-radius: 10px;
    content: " ";
    height: 20px;
    left: 50%;
    margin-left: -10px;
    position: absolute;
    width: 20px;
  }
  &:before {
    top: -25px;
  }
  &:after {
    bottom: -40px;
  }
}

.timeline li {
  &.timeline-date {
    margin-bottom: 25px;
  }
  &:nth-child(2) {
    margin-top: 0 !important;
  }
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}

/*.timeline li:not(.timeline-date) {
	margin-top: -36%;
}
body.device-sm .timeline li:not(.timeline-date) {
	margin-top: -52%;
}*/

.timeline-date {
  background-color: #eee;
  border-radius: 6px;
  color: #888;
  display: block;
  font-size: 16px;
  line-height: 32px;
  margin: 0 auto;
  text-align: center;
  width: 200px;
}

.timeline {
  li .timeline-block {
    padding: 0 10px;
    position: relative;
    width: 48%;
    float: left;
  }
  &.timeline-transparent .timeline-block .post-item {
    background-color: #fff;
    padding: 20px;
  }
  li .timeline-block {
    &:before {
      position: absolute;
      top: 8px;
      right: -4%;
      display: inline-block;
      border-top: 15px solid transparent;
      border-right: 15px solid #ccc;
      border-left: 0 solid #ccc;
      border-bottom: 15px solid transparent;
      content: " ";
    }
    .timeline-block-content {
      height: 100% !important;
      overflow: hidden;
    }
  }
}

/** panel arrows **/

.timeline-block-content h3 {
  margin-top: 0;
}

.timeline-block-image, .timeline-block-embed {
  border-bottom: 5px solid #ddd;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.timeline-block-image {
  max-height: 200px;
  img {
    margin-top: -10%;
    width: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}

.timeline li:nth-child(2n+1) .timeline-block {
  padding: 20px 0 0 10px;
  float: right;
  &:before {
    border-right-width: 0;
    border-left-width: 15px;
    left: -4%;
    right: auto;
    top: 22px;
  }
  &:after {
    border-left-width: 0;
    border-right-width: 15px;
    left: -4%;
    right: auto;
  }
}

/*Responsive*/

body {
  &.device-xs {
    .timeline:before {
      left: 0;
    }
    .timeline-circles {
      &:before, &:after {
        left: 0;
      }
    }
    .timeline li:not(.timeline-date)::before {
      left: 0;
    }
  }
  &.device-xxs {
    .timeline:before {
      left: 0;
    }
    .timeline-circles {
      &:before, &:after {
        left: 0;
      }
    }
    .timeline li:not(.timeline-date)::before {
      left: 0;
    }
  }
  &.device-xs .timeline-date, &.device-xxs .timeline-date {
    margin-left: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.device-xs .timeline li .timeline-block, &.device-xxs .timeline li .timeline-block {
    float: left;
    margin-left: 10px;
    width: 100% !important;
    padding-left: 10px;
  }
  &.device-xs .timeline li .timeline-block, &.device-xxs .timeline li .timeline-block, &.device-xs .timeline li:nth-child(2n+1) .timeline-block, &.device-xxs .timeline li:nth-child(2n+1) .timeline-block {
    padding: 20px 10px 0 20px;
  }
  &.device-xs .timeline li .timeline-block:before, &.device-xxs .timeline li .timeline-block:before {
    left: -8px;
    right: auto;
    border-left-width: 15px;
    border-right-width: 0;
  }
  &.device-xs .timeline li .timeline-block:after, &.device-xxs .timeline li .timeline-block:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
  &.device-xs .timeline.timeline-simple .timeline-date, &.device-xxs .timeline.timeline-simple .timeline-date {
    margin-left: -1px;
  }
}