
// Widgets 
// Widgets 
.widget {
    margin-bottom: 30px;
    position: relative;
    .widget-title,
    >h4 {
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 24px;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }
    a {
        font-size: 13px;
    }
    &:after,
    &:before {
        clear: both;
        content: " ";
        display: table;
    }
    iframe {
        width: 100%;
        height: auto;
    }
}

.sidebar {
    .widget {
        border-bottom: 1px solid #eee;
        margin-bottom: 30px;
        padding-bottom: 30px;
        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }
        #testimonials .testimonial-item>img {
            border-radius: 50%;
            display: block;
            float: none;
            height: 50px !important;
            margin: 0 auto 20px;
            width: 50px !important;
        }
    }
    /*    .widget-title::after {
        border-top: 4px solid #444;
        content: "";
        display: block;
        margin-top: 14px;
        width: 60px;
    }*/
}

// Widget tags

/*.widget-tags {
	.tags a {
		border: 1px solid rgba(0, 0, 0, 0.2);
        color: #777;
        display: inline-block;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 1px;
        margin: 0 2px 5px 0;
        padding: 5px 7px;
        text-decoration: none;
        text-transform: uppercase;
        -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
        transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
	}
}*/

.tags a {
    border: 2px solid #f3f3f3;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    outline: none;
    padding: 5px 14px;
    margin: 0 2px 5px 0;
    background-color: transparent;
    border-color: #ebebeb;
    color: #4c5667;
    display: inline-block;
    &:hover,
    &:focus,
    &:active,
    &.active {
        background-color: $color-theme;
        border-color: $color-theme;
        color: #fff;
    }
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}


/*.widget-tags .tags a {
    
   
    
    font-size: 11px;
    letter-spacing: 1px;

}*/

// Archive widgets
.widget-archive ul {
    padding-left: 0;
}

body {
    &.device-sm .sidebar .widget {
        float: left;
        width: 330px;
    }
    &.device-xs .sidebar .widget {
        width: 100%;
    }
    &.device-sm .sidebar .widget:nth-child(odd),
    &.device-xs .sidebar .widget:nth-child(odd) {
        margin-right: 30px;
    }
}



// Flickr widgets
.col-lg-3 .flickr-widget img {
    padding: 2px;
    width: 25%;
}

body.device-xs .col-lg-3 .flickr-widget img {
    width: 16.666%;
}

.col-lg-4 .flickr-widget img {
    padding: 2px;
    width: 20%;
}

// social widget 
.widget .footer-widget .social-icon {
    background-color: rgba(0, 0, 0, 0.2);
}



// Newsletter widgets 
.widget.widget-newsletter {
    button {
        margin-left: -1px;
    }
    button {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
    .btn {
        text-transform: none;
    }
    .form-control {
        height: 40px;
    }
    .btn {
        font-size: 12px;
        font-weight: 600;
        height: 40px;
        padding: 8px 16px;
    }
    label.error {
        color: #e42c3e;
        display: none !important;
        font-weight: 400;
        margin-top: 5px;
    }
}

.background-dark .widget.widget-newsletter {
    .input-group-prepend {
        background-color: $color-white;
        color: $color-white;
    }
    .form-transparent-fields .form-control {
        border-left: 0;
        padding-left: 0;
    }
}

.form-control.error,
.sm-form-control.error {
    border-color: #e42c3e;
}

.widget-newsletter small {
    display: block;
}

// search widegt
.widget.widget-search {
    button {
        margin-left: -1px;
    }
    button {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
    .btn {
        text-transform: none;
    }
    .btn {
        font-size: 12px;
        font-weight: 600;
        height: 40px;
        padding: 8px 16px;
    }
}

.background-dark .widget.widget-search .input-group-prepend {
    background-color: rgba(0, 0, 0, 0.35);
    border-color: rgba(0, 0, 0, 0.25);
    color: #999;
}

// Widget
#widget-contact-form {
    clear: left;
}

// Twitter
.widget-tweeter,
.widget-twitter {
    min-height: 248px;
}

.widget-tweeter li a,
.widget-twitter li a {
    color: $color-theme;
}

.widget-tweeter:not([data-avatar="true"]) li:before,
.widget-twitter:not([data-avatar="true"]) li:before {
    margin-left: -22px;
    position: absolute;
    font-family: "Font Awesome 5 Free";
    content: "\f099";
}

.widget-tweeter[data-avatar="true"] li img,
.widget-twitter[data-avatar="true"] li img {
    border-radius: 50%;
    left: -4px;
    position: absolute;
    width: 26px;
    z-index: 3;
}

.widget-tweeter[data-avatar="true"] li,
.widget-twitter[data-avatar="true"] li {
    padding-left: 10px;
}

.widget-tweeter li,
.widget-twitter li {
    margin-bottom: 18px;
}

.widget-tweeter li:last-child,
.widget-twitter li:last-child {
    margin-bottom: 0px;
}

.widget-tweeter ul,
.widget-twitter ul {
    list-style: none;
    padding-left: 22px;
    margin-bottom: 0;
}

.widget-tweeter small,
.widget-twitter small {
    display: block;
    color: #999 !important;
    width: 100%;
}

.widget-newsletter .input-group-prepend i {
    color: #555 !important;
}

 
/*Widget: Contact us*/

.widget-contact-us ul {}


/*Calendar - Datepicker Bootstrap*/


/*
.datepicker table {
    width: 100%;
}
.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget .dow,
.bootstrap-datetimepicker-widget .picker-switch,
.bootstrap-datetimepicker-widget .prev,
.bootstrap-datetimepicker-widget .next,
.bootstrap-datetimepicker-widget datepicker-decades .decade{
    text-align: center;
}
.bootstrap-datetimepicker-widget table td,
.bootstrap-datetimepicker-widget .prev,
.bootstrap-datetimepicker-widget .next,
.bootstrap-datetimepicker-widget datepicker-decades .decade {
    cursor: pointer;
}*/

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover .bootstrap-datetimepicker-widget table td.active.today,
.bootstrap-datetimepicker-widget .datepicker-decades .decade {
    background-color: $color-theme;
    color: #fff;
    border-radius: 4px !important;
}

.widget-gallery a>img {
    float: left;
    height: auto;
    width: 33.3333333%;
    padding: 3px;
}


/*Widget Tabs*/

.widget .tabs-content {
    margin-bottom: 0;
    padding-bottom: 0;
}

.sidebar .widget .tabs li>a {
    /*font-size: 11px;*/
    padding: 10px;
}

.sidebar .widget .tabs .tabs-navigation li {
    float: left;
    display: inherit;
}

.widget .post-thumbnail-content a {
    font-size: 13px;
    line-height: 20px;
    display: block;
}

.sidebar .widget .post-thumbnail-list .post-thumbnail-entry>img {
    height: 48px;
    margin-right: 10px;
    width: 64px;
}

.sidebar .widget .post-thumbnail-list .post-thumbnail-entry img+.post-thumbnail-content {
    padding-left: 72px;
}

.sidebar .widget .post-thumbnail-entry .post-thumbnail-content .post-date,
.post-thumbnail-entry .post-thumbnail-content .post-category {
    font-size: 11px;
}

.sidebar .widget .post-thumbnail-entry:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}

@media (max-width: 991px) {
    .sidebar {
        margin-bottom: 40px;
    }
}


/*Dark*/

.dark {
    .widget {
        .widget-title,
        >h4 {
            color: $color-white;
        }
    }
}
