
// Large Boxes

.box-fancy {
	> .container {
		padding: 0 30px;
	}
	[class*="col-"] {
		padding: 5% !important;
	}
	&.fancy-box-over {
		top: -100px;
	}
}
body.device-xxs .box-fancy [class*="col-"] {
    padding: 10% !important;
}
.over-slider-top {
    top: -100px;
}

// Site Map

.site-map {
	i {
		margin-right: 8px;
	}
	ul li {
		padding-bottom: 2px;
	}
}

 // Animations

 .animated {
 	&.infinite {
 		animation-duration: 3s;
 		animation-fill-mode: both;
 	}
 }

 [data-animation] {
 	opacity: 0;
 }
 .icon[data-animation] {
 	opacity:1;
 }