//
//** ion Range Slider Component
//

//==== Component Mixins ====//

//== Component Base
@mixin m-build-component--ion-range-slider-base() {
    .m-ion-range-slider {
        .irs {
            .irs-min,
            .irs-max,
            .irs-from,
            .irs-to,
            .irs-single {
                padding: 2px 5px 1px 5px;
            }
        }
    }
} 

//==== Generate Component ====//

//== Component Base
@include m-build-component--ion-range-slider-base();




.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge,
.irs-slider {
}

.irs {
    height: 40px;
}
.irs-with-grid {
    height: 60px;
}
.irs-line {
    height: 12px; top: 25px;
}
    .irs-line-left {
        height: 6px;
        background: $color-grey;
        top: 5px;
    }
    .irs-line-mid {
        height: 6px;
        background:  $color-grey;
        top: 5px;
    }
    .irs-line-right {
        height: 6px;
        background: $color-grey;
        top: 5px;
    }

.irs-bar {
    height: 6px;
    top: 30px;
    background-position: 0 -60px;
    background: $color-theme;
}
    .irs-bar-edge {
        top: 30px;
        height: 6px;
        width: 9px;
        background: $color-grey;
    }

.irs-shadow {
    height: 3px; top: 34px;
    background: $color-black;
    opacity: 0.25;
}
.lt-ie9 .irs-shadow {
    filter: alpha(opacity=25);
}

.irs-slider {
    width: 18px;
    height: 18px;
    top: 22px;
    background:  $color-white;
    box-shadow: 0px 0px 14px rgba(0,0,0,0.2);
    border-radius: 50%;
    cursor: pointer;
    background: $color-white;
}
.irs-slider.state_hover, .irs-slider:hover {
    background-position: 0 -150px;
}

.irs-min, .irs-max {
    color: #999;
    font-size: 10px; line-height: 1.333;
    text-shadow: none;
    top: 0; padding: 1px 3px;
    background: #e1e4e9;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.irs-from, .irs-to, .irs-single {
    color: $color-white;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 2px 8px;
    background: $color-theme;
    -moz-border-radius: 4px;
    border-radius: 4px;
    /* box-shadow: 0px 0px 12px rgba(0,0,0,0.2); */
}
.irs-from:after, .irs-to:after, .irs-single:after {
    position: absolute;
    display: block;
    content: "";
    bottom: -10px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -5px;
    overflow: hidden;
    border: 5px solid transparent;
    border-top-color: $color-theme;
}


.irs-grid-pol {
    background: $color-light;
}
.irs-grid-text {
color: $color-master;
}

.irs-disabled {
}
 

.range-color-primary {
    .irs-from:after, .irs-to:after, .irs-single:after { 
        border-top-color:  $color-primary;  
    }

     .irs-bar, .irs-from, .irs-to, .irs-single {
        background: $color-primary;
    }
}

.range-color-secondary {

    .irs-from:after, .irs-to:after, .irs-single:after { 
        border-top-color:  $color-secondary;
   }
    .irs-bar, .irs-from, .irs-to, .irs-single {
        background: $color-secondary;
    }
    
}

.range-color-success {
        .irs-from:after, .irs-to:after, .irs-single:after { 
            border-top-color:  $color-success;  
            }

         .irs-bar, .irs-from, .irs-to, .irs-single {
        background: $color-success;
    }
    
}

.range-color-info {
        .irs-from:after, .irs-to:after, .irs-single:after { 
            border-top-color:  $color-info; 
         } 

        .irs-bar, .irs-from, .irs-to, .irs-single {
        background: $color-info;
    }
    
}

.range-color-danger {
        .irs-from:after, .irs-to:after, .irs-single:after { 
            border-top-color:  $color-danger;  
        } 

        .irs-bar, .irs-from, .irs-to, .irs-single {
        background: $color-danger;
    }
    
}

.range-color-warning {
        .irs-from:after, .irs-to:after, .irs-single:after { border-top-color:  $color-warning;  }

         .irs-bar, .irs-from, .irs-to, .irs-single {
        background: $color-warning;
    }
    
}

    

