/* ----------------------------------------------------------------------
	Parallax
-------------------------------------------------------------------------*/

// DELETE VARIABLES
//Parallax
.parallax-container {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -100;
}
.parallax-container.parallax-responsive {
    background-size: cover !important;
    background-attachment: scroll !important;
    background-position: center center !important;
}
section[data-parallax-image] {
    background-color: transparent;
}
