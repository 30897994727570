/* ----------------------------------------------------------------
	Responsive CSS
-----------------------------------------------------------------*/


/*
Large devices
Desktops (≥1200px) 
*/

@media (min-width: 1240px) {
    body.boxed #wrapper,
    body.boxed-simple #wrapper {
        float: none;
        width: 1220px;
        margin: 0 auto;
    }
    body.boxed-simple #wrapper {
        margin-top: 40px;
        margin-bottom: 40px;
        padding: 20px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .news-section-wrapper {
        padding: 0 40px
    }
}


/*
Medium devices
Desktops (≥992px)
*/

@media (max-width: 991px) {

    
    #header #header-wrap #logo {
    margin: 0 auto !important;
    max-width: 130px;
    position: absolute !important;
    z-index: 2;
    }
    #wrapper {
        width: 100%;
        margin: 0;
    }
    .p-t-200 {
        padding-top: 80px !important;
    }
    .p-b-200 {
        padding-bottom: 80px !important;
    }
    .text-sm-center {
        text-align: center !important;
    }
    /*	Grid post*/
    .grid-articles:not(.post-carousel) .post-entry {
        width: 50%;
    }
    .grid-articles:not(.post-carousel) .post-entry:first-child {
        width: 100%;
    }
    .grid-articles:not(.post-carousel) .post-entry:first-child .post-entry-meta .post-entry-meta-title h2 {
        font-size: 20px;
    }
    .grid-articles:not(.post-carousel) .post-entry .post-entry-meta .post-entry-meta-title h2 {
        font-size: 13px;
    }
    .grid-articles:not(.post-carousel) .post-entry .post-entry-overlay {
        border-width: 0 !important;
    }
    .post-thumbnails .post-item:not(.quote) .post-item-description {
        width: 100% !important;
    }
    /*	hide news ticker*/
    .news-ticker {
        display: none;
    }
    .news-section-wrapper {
        padding: 0px
    }
    /*  widgets*/
    .widget {
        float: left;
        width: 100%;
    }
    /* shop*/
    .mfp-ajax-holder .mfp-close {
        margin-right: 0;
    }
    .mfp-close {
        font-size: 22px;
        height: 30px;
        line-height: 32px;
        width: 30px;
    }
    /*    maps*/
    .map {
        min-height: 240px;
    }
    /*helper classes*/
    .sm-text-center {
        text-align: center !important;
    }
    .sm-text-left {
        text-align: left !important;
    }
    .sm-text-right {
        text-align: right !important;
    }
    .sm-left {
        float: left !important;
    }
    .sm-right {
        float: right !important;
    }
    .sm-center {
        float: none !important;
        margin: 0 auto;
    }
    .row.vertical-align {
        display: block;
    }
    .sm-m-b-0 {
        margin-bottom: 0px !important
    }
    .sm-m-b-5 {
        margin-bottom: 5px!important
    }
    .sm-m-b-10 {
        margin-bottom: 10px!important
    }
    .sm-m-b-15 {
        margin-bottom: 15px!important
    }
    .sm-m-b-20 {
        margin-bottom: 20px!important
    }
    .sm-m-b-25 {
        margin-bottom: 25px!important
    }
    .sm-m-b-30 {
        margin-bottom: 30px!important
    }
    .sm-m-b-35 {
        margin-bottom: 35px!important
    }
    .sm-m-b-40 {
        margin-bottom: 40px!important
    }
    .sm-m-b-50 {
        margin-bottom: 50px!important
    }
    .sm-m-b-60 {
        margin-bottom: 60px!important
    }
    .sm-m-b-70 {
        margin-bottom: 70px!important
    }
    .sm-m-b-80 {
        margin-bottom: 80px!important
    }
    .sm-m-b-90 {
        margin-bottom: 90px!important
    }
    .sm-m-b-100 {
        margin-bottom: 100px!important
    }
    .sm-m-b-150 {
        margin-bottom: 150px!important
    }
    .sm-m-b-200 {
        margin-bottom: 200px!important
    }
    .sm-m-t-0 {
        margin-top: 0px!important
    }
    .sm-m-t-5 {
        margin-top: 5px!important
    }
    .sm-m-t-10 {
        margin-top: 10px!important
    }
    .sm-m-t-15 {
        margin-top: 15px!important
    }
    .sm-m-t-20 {
        margin-top: 20px!important
    }
    .sm-m-t-25 {
        margin-top: 25px!important
    }
    .sm-m-t-30 {
        margin-top: 30px!important
    }
    .sm-m-t-35 {
        margin-top: 35px!important
    }
    .sm-m-t-40 {
        margin-top: 40px!important
    }
    .sm-m-t-50 {
        margin-top: 50px!important
    }
    .sm-m-t-60 {
        margin-top: 60px!important
    }
    .sm-m-t-70 {
        margin-top: 70px!important
    }
    .sm-m-t-80 {
        margin-top: 80px!important
    }
    .sm-m-t-90 {
        margin-top: 90px!important
    }
    .sm-m-t-100 {
        margin-top: 100px!important
    }
    .sm-m-l-0 {
        margin-left: 0px!important;
    }
    .sm-m-l-5 {
        margin-left: 5px!important
    }
    .sm-m-l-10 {
        margin-left: 10px!important
    }
    .sm-m-l-15 {
        margin-left: 15px!important
    }
    .sm-m-l-20 {
        margin-left: 20px!important
    }
    .sm-m-l-25 {
        margin-left: 25px!important
    }
    .sm-m-l-30 {
        margin-left: 30px!important
    }
    .sm-m-l-35 {
        margin-left: 35px!important
    }
    .sm-m-l-40 {
        margin-left: 40px!important
    }
    .sm-m-l-50 {
        margin-left: 50px!important
    }
    .sm-m-l-60 {
        margin-left: 60px!important
    }
    .sm-m-l-70 {
        margin-left: 70px!important
    }
    .sm-m-l-80 {
        margin-left: 80px!important
    }
    .sm-m-l-90 {
        margin-left: 90px!important
    }
    .sm-m-l-100 {
        margin-left: 100px!important
    }
    .sm-m-r-0 {
        margin-right: 0px!important
    }
    .sm-m-r-5 {
        margin-right: 5px !important;
    }
    .sm-m-r-10 {
        margin-right: 10px!important
    }
    .sm-m-r-15 {
        margin-right: 15px!important
    }
    .sm-m-r-20 {
        margin-right: 20px!important
    }
    .sm-m-r-25 {
        margin-right: 25px!important
    }
    .sm-m-r-30 {
        margin-right: 30px!important
    }
    .sm-m-r-35 {
        margin-right: 35px!important
    }
    .sm-m-r-40 {
        margin-right: 40px!important
    }
    .sm-m-r-50 {
        margin-right: 50px!important
    }
    .sm-m-r-60 {
        margin-right: 60px!important
    }
    .sm-m-r-70 {
        margin-right: 70px!important
    }
    .sm-m-r-80 {
        margin-right: 80px!important
    }
    .sm-m-r-90 {
        margin-right: 90px!important
    }
    .sm-m-r-100 {
        margin-right: 100px!important
    }
    .sm-m-0 {
        margin: 0px!important;
    }
    .sm-m-5 {
        margin: 5px!important
    }
    .sm-m-10 {
        margin: 10px!important
    }
    .sm-m-15 {
        margin: 15px!important
    }
    .sm-m-20 {
        margin: 20px!important
    }
    .sm-m-25 {
        margin: 25px!important
    }
    .sm-m-30 {
        margin: 30px!important
    }
    .sm-m-35 {
        margin: 35px!important
    }
    .sm-m-40 {
        margin: 40px!important
    }
    .sm-m-50 {
        margin: 50px!important
    }
    .sm-m-60 {
        margin: 60px!important
    }
    .sm-m-70 {
        margin: 70px!important
    }
    .sm-m-80 {
        margin: 80px!important
    }
    .sm-m-90 {
        margin: 90px!important
    }
    .sm-m-100 {
        margin: 100px!important
    }
    /* ----------------------------------------------------------------
        Creative Profile Page
    -----------------------------------------------------------------*/
    .profile-content .profile-image {
        height: 300px;
        position: relative;
        width: 100%;
        display: table;
    }
    .profile-content .profile-image .profile-name {
        display: table-cell;
        height: 100%;
        padding: 30px;
        position: relative;
        vertical-align: bottom;
        width: 100%;
    }
    .profile-content .profile-image .profile-name h3 {
        font-size: 70px;
        line-height: 20px;
        margin-bottom: 10px;
        text-align: center;
    }
    .profile-content .profile-bio {
        display: block;
        float: none;
        height: auto;
        /*  margin: 0;
  padding: 20px;*/
        margin: 15px auto;
        padding: 15px;
        width: 750px;
    }
    /*    #header.header-fullwidth #header-wrap .container {
        padding: 0 15px;
        
    }*/
    .modal-strip.modal-active .vertical-align {
        top: 0 !important;
        transform: translateY(0px) !important;
    }
}


/*
Small devices
Tablets (≥768px)
*/

@media (max-width: 767px) {
    #wrapper {
        width: 100% !important;
    }
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-19,
    .col-lg-11,
    .col-lg-12 {
        clear: left;
        width: 100%;
    }
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
        width: 100% !important;
    }
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
        width: 100% !important;
    }
    /* helper classes*/
    section {
        padding: 40px 0;
    }
    /*helper classes*/
    .xs-text-center {
        text-align: center !important;
    }
    .xs-text-left {
        text-align: left !important;
    }
    .xs-text-right {
        text-align: right !important;
    }
    /*paddings*/
    .p-xs-20 {
        padding: 20px !important;
    }
    /*	Creative Profile Page */
    .profile-content .profile-image .profile-name h3 {
        display: none;
    }
    .profile-content .profile-bio {
        width: 470px;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .container {
        width: 470px !important;
    }
    /*	post columns*/
    .post-2-columns .post-item,
    .post-3-columns .post-item,
    .post-4-columns .post-item,
    .post-5-columns .post-item,
    .post-6-columns .post-item {
        margin-right: 2%;
        width: 98%;
    }
    /*	TYPOGRAPHY*/
    .text-large,
    .text-large span,
    .text-large span span,
    .text-large-light,
    .text-large-light span,
    .text-large-light span span,
    .text-extra-large,
    .text-extra-large span,
    .text-lg,
    .text-lg span,
    .text-lg span span {
        font-size: 40px !important;
        line-height: 40px !important;
    }
    .profile-content .profile-bio {
        margin: 20px auto;
        padding: 20px;
    }
}

@media (max-width: 479px) {
    .container {
        width: 100% !important;
    }
    /*	Grid post*/
    .grid-articles:not(.post-carousel) .post-entry,
    .grid-articles:not(.post-carousel) .post-entry:first-child,
    .grid-articles.grid-articles-v2:not(.post-carousel) .post-entry:nth-child(1),
    .grid-articles.grid-articles-v2:not(.post-carousel) .post-entry:nth-child(2) {
        width: 100%;
    }
    .grid-articles:not(.post-carousel) .post-entry:first-child .post-entry-meta .post-entry-meta-title h2 a,
    .grid-articles.grid-articles-v2:not(.post-carousel) .post-entry:nth-child(1) .post-entry-meta .post-entry-meta-title h2 a,
    .grid-articles.grid-articles-v2:not(.post-carousel) .post-entry:nth-child(2) .post-entry-meta .post-entry-meta-title h2 a {
        font-size: 16px;
    }
    /*	post columns*/
    .post-2-columns .post-item,
    .post-3-columns .post-item,
    .post-4-columns .post-item,
    .post-5-columns .post-item,
    .post-6-columns .post-item {
        margin-right: 0%;
        width: 100%;
    }
    /*	blog*/
    .post-thumbnail .post-image,
    .post-thumbnail .post-video,
    .post-thumbnail .post-audio,
    .post-thumbnail .post-embed,
    .post-thumbnail .post-slider,
    .post-thumbnail .post-content-details,
    .post-thumbnail .post-meta {
        width: 100%;
        padding: 0;
    }
    /*****SHORTCODES *****/
    /*countdown*/
    .countdown .countdown-box {
        height: 60px !important;
        padding: 2px 0px 0 0 !important;
        width: 60px !important;
        margin: 0px !important;
        border: solid 0px !important;
    }
    .countdown .countdown-box .number {
        font-size: 20px !important;
        margin-bottom: 0px;
    }
    .countdown .countdown-container .countdown-box span {
        font-size: 10px !important;
        padding-top: 0px;
    }
    /*	TYPOGRAPHY*/
    .text-large,
    .text-large span,
    .text-large span span,
    .text-large-light,
    .text-large-light span,
    .text-large-light span span,
    .text-extra-large,
    .text-extra-large span,
    .text-lg,
    .text-lg span,
    .text-lg span span {
        font-size: 40px !important;
        line-height: 40px !important;
    }
    .hero-heading-3 {
        background-color: #fff;
        border: 30px solid #fff;
        padding: 30px;
        width: 100%;
        margin: 0;
    }
    .hero-heading-3 h2,
    .hero-heading-3 h3 {
        font-size: 40px !important;
        line-height: 40px !important;
    }
    /*	Creative Profile Page */
    .profile-content .profile-image {
        height: 200px;
    }
    .profile-content .profile-bio {
        margin: 0;
        padding: 20px;
        width: 100%;
    }
}
