 // DELETE VARIABLES
 /* ----------------------------------------------------------------------
Breadcrumbs
-------------------------------------------------------------------------*/
.breadcrumb-item {
    float: left;
}
 .breadcrumb {
     background-color: transparent;
     font-size: 12px;
     margin: 0 0 10px 0;
     padding: 0;
     text-align: left;
     display: block;
     ul, ol {
         display: inline;
         margin: 0;
         padding: 0;
         li {
             display: inline;
             position: relative;
             margin-left: 10px;
             &:first-child {
                margin-left: 0px; 
             }
            
             + li:before {
                 content: "";
                 display: block;
                 width: 2px;
                 height: 2px;
                 position: absolute;
                 top: 50%;
                 left: -7px;
                 background-color: $dark-light;
              
             }
             a {
                 color: $dark-light;
             }
         }
         .breadcrumb-item {
             float: left !important;
         }
     }
     &.fancy {
         overflow: hidden;
         font-size: 12px;
         line-height: 18px;
         background-color: transparent;
         padding: 0;
         margin-bottom: 20px;
         margin-top: 14px;
         li {
             line-height: 36px;
             margin: 0 2px 0 10px;
             + li:before {
                 content: none;
             }
         }
     }
     &.radius li + li:before {
         content: none;
     }
     &.fancy {
         li:first-child i {
             margin-right: -6px;
         }
         a {
             background: #eee;
             padding: 5px 12px;
             text-decoration: none;
             position: relative;
             &:hover {
                 background: #ddd;
                 color: #333; 
             }
         }
         .active a {
             background: #ddd;
             color: #333;
         }
         a:before {
             content: "";
             position: absolute;
             top: 50%;
             margin-top: -14px;
             border-width: 14px 0 13px 10px;
             border-style: solid;
             border-color: #eee #eee #eee transparent;
             left: -10px;
         }
         li:first-child {
             margin: 0 2px 0 0;
             a::before {
                 border-width: 0;
             }
         }
         a:hover::before,
         .active a:before {
             border-color: #ddd #ddd #ddd transparent;
         }
         a {
             &::after {
                 content: "";
                 position: absolute;
                 top: 50%;
                 margin-top: -14px;
                 border-top: 14px solid transparent;
                 border-bottom: 12px solid transparent;
                 border-left: 10px solid #eee;
                 right: -10px;
             }
             &:hover::after {
                 border-left-color: #ddd;
             }
         }
         .active a:after {
             border-left-color: #ddd;
         }
     }
     &.radius {
         background: transparent;
         overflow: hidden;
         font-size: 12px;
         li {
             margin-right: 16px;
         }
         a:hover,
         .active a {
             background: #ddd;
             color: #333;
         }
         a,
         .active a {
             background: #eee;
             padding: 4px 8px;
             text-decoration: none;
             position: relative;
         }
         a {
             &:hover {
                 background: #ddd;
             }
             &::before,
             &::after {
                 background: none repeat scroll 0 0 #eee;
                 bottom: 0;
                 content: "";
                 height: 25px;
                 position: absolute;
                 top: 0;
                 -webkit-transform: skew(-8deg);
                 -ms-transform: skew(-8deg);
                 transform: skew(-8deg);
                 width: 10px;
             }
             &::before {
                 left: -.5em;
                 border-radius: 5px 0 0 5px;
             }
             &:hover::before {
                 background: #ddd;
             }
             &::after {
                 right: -.5em;
                 border-radius: 0 5px 5px 0;
             }
             &:hover::after {
                 background: #ddd;
             }
         }
         .active:hover {
             background: #ddd;
         }
         a:hover::after,
         .active a:after {
             border-left-color: #ddd;
         }
     }
 }
