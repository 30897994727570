/* ----------------------------------------------------------------
	Typography
-----------------------------------------------------------------*/


::selection {
    background: $color-theme;
    color: #FFF;
    text-shadow: none;
}

::-moz-selection {
    background: $color-theme;
    /* Firefox */
    color: #FFF;
    text-shadow: none;
}

::-webkit-selection {
    background: $color-theme;
    /* Safari */
    color: #FFF;
    text-shadow: none;
}

:active,
:focus {
    outline: none !important;
}


/** Typography **/


/* ----------------------------------------------------------------
	Typography : Reset
-----------------------------------------------------------------*/
*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a {
  margin: 0;
  padding: 0;
}

/* ----------------------------------------------------------------
	Typography : Font size
-----------------------------------------------------------------*/

/*html {
  font-size: 87.5%;
}
@media all and (max-width: 768px) {
  html {
    font-size: 81.25%;
  }
}*/

body {
    font-size: 1em;
    line-height: 1.65714286em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font-family-1;
    color: $color-body;
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    color: $dark;
    margin: 0; 
    font-weight: 300;
    small {
        font-weight: 100;
    }
    letter-spacing: 1px;
} 

h1,
.h1 {
    font-size: 3.14285714em;
    line-height: 1.31818182em;
    &:not(:last-child) {
        margin-bottom: 0.59090909090909em;
    }
}

h2,
.h2 {
    font-size: 2.35714286em;
    line-height: 1.36363636em;
    font-weight: 600;
    &:not(:last-child) {
        margin-bottom: 0.78787878787879em;
    }
}

h3,
.h3 {
    font-size: 1.78571429em;
    line-height: 1.5em;
    font-weight: 600;
    &:not(:last-child) {
        margin-bottom: 1.04em;
    }
}

h4,
.h4 {
    font-size: 1.35714286em;
    line-height: 1.68421053em;
    font-weight: 600;
    &:not(:last-child) {
        margin-bottom: 0.700842105263158em;
    }
}

h5,
.h5 {
    font-size: 1em;
    line-height: 1.85714286em;
    font-weight: 600;
    &:not(:last-child) {
        margin-bottom: 0.600842105263158em;
    }
}

h6,
.h6 {
    font-size: 0.85714286em;
    line-height: 2.16666667em;
    font-weight: 600;
    &:not(:last-child) {
        margin-bottom: 0.500842105263158em;
    }
}

.lead {
    font-size: 1.35714286em;
    line-height: 1.68421053em;
}

@media all and (max-width: 767px) {
    h1,
    .h1 {
        font-size: 2.35714286em;
        line-height: 1.36363636em;
    }
    h2,
    .h2 {
        font-size: 1.78571429em;
        line-height: 1.5em;
    }
    h3, 
    .h3 {
        font-size: 1.35714286em;
        line-height: 1.85714286em;
    }
    .lead {
        font-size: 1.35714286em;
        line-height: 1.68421053em;
    }
}

p {
    font-family: $font-family-1;
    font-size: 1em; 
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    line-height: 1.6;
    letter-spacing: 0;
    color: $paragraph-color;
} 


p,
ul,
ol,
pre,
table {
    margin-bottom: 1.85714286em;
}

ul.bullets {
    list-style: inside;
}

strong {
    font-weight: 600;
}


/*hr {
    margin: 1.85714286em 0;
    border-color: #fafafa;
}*/

a {
    color: $color-body;
    &:hover,
    &:focus,
    &:active,
     {
        text-decoration: none;
        outline: none;
        color: $color-theme;
    }
    &:not([href]):not([tabindex]){
        color: $color-body;
    }

}



// Fonts
.font-pacifico {
    font-family: 'Dancing Script' !important;
}

.font-raleway {
    a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Raleway' !important;
        font-weight: 100 !important;
    }
}

h1.font-raleway,
h2.font-raleway,
h3.font-raleway,
h4.font-raleway,
h5.font-raleway,
h6.font-raleway {
    font-family: 'Raleway' !important;
    font-weight: 100 !important;
}

.font-nothing-you-could-do,
.font-nothing-you-could-do a,
.font-nothing-you-could-do h1,
.font-nothing-you-could-do h2,
.font-nothing-you-could-do h3,
.font-nothing-you-could-do h4,
.font-nothing-you-could-do h5,
.font-nothing-you-could-do h6,
h1.font-nothing-you-could-do,
h2.font-nothing-you-could-do,
h3.font-nothing-you-could-do,
h4.font-nothing-you-could-do,
h5.font-nothing-you-could-do,
h6.font-nothing-you-could-do {
    font-family: 'Nothing You Could Do', cursive !important;
    font-weight: normal !important;
}

.font-herr-von-muellerhoff,
.font-herr-von-muellerhoff a,
.font-herr-von-muellerhoff h1,
.font-herr-von-muellerhoff h2,
.font-herr-von-muellerhoff h3,
.font-herr-von-muellerhoff h4,
.font-herr-von-muellerhoff h5,
.font-herr-von-muellerhoff h6,
h1.font-herr-von-muellerhoff,
h2.font-herr-von-muellerhoff,
h3.font-herr-von-muellerhoff,
h4.font-herr-von-muellerhoff,
h5.font-herr-von-muellerhoff,
h6.font-herr-von-muellerhoff {
    font-family: 'Herr Von Muellerhoff', cursive !important;
    font-weight: normal !important;
}

.font-cedarville,
.font-cedarville a,
.font-cedarville h1,
.font-cedarville h2,
.font-cedarville h3,
.font-cedarville h4,
.font-cedarville h5,
.font-cedarville h6,
h1.font-cedarville,
h2.font-cedarville,
h3.font-cedarville,
h4.font-cedarville,
h5.font-cedarville,
h6.font-cedarville {
    font-family: 'Cedarville Cursive', cursive;
}

.font-parisienne,
.font-parisienne a,
.font-parisienne h1,
.font-parisienne h2,
.font-parisienne h3,
.font-parisienne h4,
.font-parisienne h5,
.font-parisienne h6,
h1.font-parisienne,
h2.font-parisienne,
h3.font-parisienne,
h4.font-parisienne,
h5.font-parisienne,
h6.font-parisienne {
    font-family: 'Parisienne', cursive;
}

.heading-1 {
    text-align: center;
    h1 {
        margin-bottom: 40px;
        font-size: 70px;
        line-height: 90px;
        color: $color-black;
        font-weight: 600;
    }
    p {
        padding-left: 40px;
        padding-right: 40px;
        font-size: 34px;
        font-weight: 300;
        color: $color-black;
        line-height: 50px;
        margin-bottom: 40px;
        span {
            font-weight: 600;
        }
    }
    &.text-left,
    &.text-right {
        p {
            padding: 0;
        }
    }
}

.heading-2 {
    text-align: center;
    h1 {
        margin-bottom: 30px;
        font-size: 56px;
        line-height: 70px;
        color: $color-black;
        font-weight: 600;
    }
    p {
        font-size: 22px;
        font-weight: 300;
        color: $color-black;
        line-height: 36px;
        margin-bottom: 40px;
        span {
            font-weight: 600;
        }
    }
    &.text-left,
    &.text-right {
        p {
            padding: 0;
        }
    }
}

.heading-3 {
    text-align: center;
    margin-bottom: 40px;
    h1,
    h2,
    h3 {
        color: $color-black;
        margin-bottom: 20px;
        font-size: 59px;
        font-weight: 100;
        letter-spacing: -1px;
        line-height: 70px;
    }
    p {
        font-size: 17px;
        font-weight: 300;
        line-height: 30px;
        margin-bottom: 20px;
        span {
            font-weight: 600;
        }
    }
    &.text-left,
    &.text-right {
        p {
            padding: 0;
        }
    }
}

.heading-4 {
    text-align: center;
    margin-bottom: 40px;
    h1,
    h2,
    h3 {
        font-family: $font-family-5;
        color: $color-black;
        font-weight: 300;
        font-size: 50px;
        letter-spacing: -0.02em;
    }
    p {
font-size: 18px;
    line-height: 1.75;
        font-family: $font-family-4;
        span {
            font-weight: 600;
        }
    }
    &.text-left,
    &.text-right {
        p {
            padding: 0;
        }
    }
}

.marquee-title {
    white-space: nowrap;
    font-weight: 700;
    font-size: 165px;
    word-spacing: 0px;
    font-family: 'Poppins', sans-serif;
    color: #eeeeee;
    margin-bottom: -60px;
}
