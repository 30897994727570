/* ----------------------------------------------------------------
    BODY
-----------------------------------------------------------------*/

html {
    overflow-x: hidden;
}

/* Layout boxed & wide */

@media(min-width:576px) {
 .container {
  max-width:540px
 }
}
@media(min-width:768px) {
 .container {
  max-width:750px
 }
}
@media(min-width:992px) {
 .container {
  max-width:970px
 }
}
@media(min-width:1200px) {
 .container {
  max-width:1170px
 }
}
@media(max-width:575px) {
#header.fullwidth-header .container,
 .container-fullwidth {
  width:100%!important;
  padding-left:40px!important;
  padding-right:40px!important
 }
}
// Wide Default
body {
    #wrapper {
        padding: 0;
        margin: 0;
        overflow-x: hidden;
    }
    &.wide #wrapper {
        width: 100%;
        margin: 0;
    }
    //Boxed
    &.boxed #wrapper,
    &.boxed-simple #wrapper {
        position: relative;
        border: 1px solid $light;
        background-color: $color-white;
    }
    &.outter {
        margin: 30px;
    }
    &.boxed-simple {
        background-color: #f8f8f8;
        border: 0 none !important;
    }
    
}
.container-wide {
        padding-left: 30px;
        padding-right: 30px;
    }
/* Responsive */

@media (max-width: 479px) {
    body {
        font-size: 13px;
        .outter {
            margin: 0px;
        }
    }
}
@media (min-width: 1240px) {
    body.boxed #wrapper,
    body.boxed-simple #wrapper {
        float: none;
        width: 1220px;
        margin: 0 auto;
    }
    body.boxed-simple #wrapper {
        margin-top: 40px;
        margin-bottom: 40px;
        padding: 20px;
    }
    .container-fluid {
        padding-left: 30px;
        padding-right: 30px;
    }
    .container-wide {
        padding-right: 8%;
        padding-left: 8%;
        margin: 0 auto;
    }
}
audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}
iframe {
    border: none;
}

/* Sections */

section {
    padding: 80px 0;
    position: relative;
    z-index: auto;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    background-color: $color-white;
}