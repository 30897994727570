// Fichier global a toute l'app
// Template polo
@import "polo/style";
// fichiers commun de l'app
@import "amrae/univers";

#header {
    height: auto;

    #logo {
        .logo {
            display: block;
            max-width: 200px;
            margin: 0 auto;
        }
    }

    .contact-block {
        padding-top: 30px;
        p{
            span,
            a{
                color: #ffffff !important;
            }
        }
    }
}

html {
    height: 100%;
}

body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-family: 'Barlow', sans-serif !important;
}

p {
    font-family: 'Barlow', sans-serif !important;
}

.container-wrapper {
    width: 100%;
    flex: 1 0 auto;
    padding: 30px 0;
    display: flex;
    align-items: center;

    @media (min-width: 768px) {
        padding: 50px 0;
    }

    .front-wrapper {
        width: 100%;
    }
    
    &.current {
        align-items: flex-start;
        
        .btn-secondary {
            @media (max-width: 767px) {
                display: block;
                margin: 0 auto;
                font-size: 14px;
            }
        }
    }

    .btn-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}


.footer {
    width: 100%;
    background: #eeecf5;
    color: #2F1F87;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    height: 38px;
    line-height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;

    .social-network {
        margin-right: 5px;
    }

    a {
        color: #2F1F87;
    }
}

.btn-custom-1 {
    background: $color-book-pink;

    &:hover {
        background: darken($color-book-pink, 15%);
    }
}

.btn-custom-2 {
    background: $color-book-grey;
    color: $color-font;

    &:hover {
        background: darken($color-book-grey, 15%);
        color: $color-white;
    }
}

.color-white {
    color: $color-white;
}

.form-group.has-error {
    label {
        color: #b71828 !important;
    }

    label.error {
        padding-left: 16px;
        padding-top: 8px;
        display: inline-block !important;
    }

}

.btn-modal-full {
    position: absolute;
    right: 10;
    top: 10;
    cursor: pointer;
    color: $color-white;
    background: none;
    border: none;
}

header {
    .logos {

        div{
            padding: 20px;
            display: flex;
            align-items: center;

            .logo {
                height: 60px;

                @media (min-width: 768px) {
                    height: 80px;
                }
            }
        }

        .account {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #eeecf5;
            font-weight: 500;

            @media (min-width: 768px) {
                margin-left: auto;
                width: initial;
            }

            .btn-primary {
                margin-top: 20px;
            }
        }
    }

    nav {
        border-top: 1px solid #2f1f87;
        border-bottom: 1px solid #2f1f87;
        .trigger-nav {
            width: 30px;
            height: 26px;
            cursor: pointer;
            position: relative;
            margin: 10px 20px;

            @media (min-width: 768px) {
                display: none;
            }
        
            span {
                display: block;
                width: 100%;
                height: 2px;
                border-radius: 1px;
                position: absolute;
                left: 0;
                transition: all 0.25s ease-in-out;
                opacity: 1;
                background-color: #2f1f87;
                border-radius: 0;
        
        
        
                &:nth-child(1) {
                    top: 0;
                }
        
                &:nth-child(2),
                &:nth-child(3) {
                    top: 12px;
                }
        
                &:nth-child(4) {
                    bottom: 0;
                }
            }
        
            &.open {
                span {
                    &:nth-child(2) {
                        transform: rotate(45deg);
                    }
        
                    &:nth-child(3) {
                        transform: rotate(-45deg);
                    }
        
                    &:nth-child(1),
                    &:nth-child(4) {
                        width: 0;
                        top: 12px;
                        left: 50%;
                    }
                }
            }
        }

        >ul {
            display: none;
            
            @media (min-width: 768px) {
                display: flex;
                list-style: none;
                margin-bottom: 0;
            }

            li {
                a {
                    display: block;
                    padding: 10px 25px;
                    color: #2f1f87 !important;
                    text-decoration: none;
                    font-weight: 500;
                    font-size: 16px;
                    transition: all 0.3s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        background-color: #2f1f87;
                        color: #fff !important;
                        text-decoration: none;
                    }
                }

                &.menu-item-has-children {
                    position: relative;
    
                    .sub-menu {
                        list-style: none;
                        background-color: #fff;
                        padding-left: 20px;

                        @media (min-width: 768px) {
                            display: none;
                            position: absolute;
                            z-index: 5;
                            top: 44px;
                            left: 0;
                            padding-left: 0;
                            border: 1px solid #2f1f87;
                        }

                        li {
                            a {
                                width: 100%;
                                font-size: 15px;
                            }
                        }
                    }
                }
            } 
        }
    }
}

.btn-primary {
    color: #fff;
    background-color: #2f1f87 !important;
    border-radius: 4px !important;
    border: 0 !important;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    

    &:hover,
    &:active,
    &:focus {
        color: #fff;
        background-color: #2f1f87 !important;
        -webkit-box-shadow: 5px 5px 15px 5px #a3a2aa !important; 
        box-shadow: 5px 5px 15px 5px #a3a2aa !important;
        border: 0 !important;
    }
}

.btn-secondary {
    color: #fff;
    background-color: #DF0345 !important;
    border-radius: 4px !important;
    border: 0 !important;
    transition: all 0.3s ease-in-out;

    @media (max-width: 767px) {
        font-size: 14px !important;
    }

    &:hover,
    &:active,
    &:focus {
        color: #fff;
        background-color: #DF0345 !important;
        -webkit-box-shadow: 5px 5px 15px 5px #a3a2aa !important; 
        box-shadow: 5px 5px 15px 5px #a3a2aa !important;
        border: 0 !important;
    }
}

.btn-report {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (min-width: 992px) {
        justify-content: flex-end;
    }

    a {
        font-size: 13px;
        
        @media (min-width: 1200px) {
            font-size: 1rem;
        }
    }
}
