
.item-link {
    color: $color-theme;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase !important;
    i {
        position: relative;
        right: 0;
        transition: right 0.3s ease 0s;
        top: -1px;
    }
    &:hover {
        color: $color-theme;
        i {
            right: -6px;
        }
    } 
}

.nav-link {
    color: $color-theme;
}




.background-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.background-fixed {
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
}

.no-parallax {
    background-size: cover !important;
    background-attachment: scroll !important;
    background-position: center center !important;
}

.background-overlay-gradient-colored>.container,
.background-overlay-gradient>.container,
.background-overlay-gradient-dark>.container,
.background-overlay>.container,
.background-overlay-one>.container,
.background-overlay-dark>.container,
.background-overlay-light>.container,
.background-overlay-grey>.container,
.background-overlay-grey-dark>.container,
.background-overlay-dark>.container,
#particles-js>.container,
.background-overlay-gradient-colored>.container-fluid,
.background-overlay-gradient>.container-fluid,
.background-overlay-gradient-dark>.container-fluid,
.background-overlay>.container-fluid,
.background-overlay-one>.container-fluid,
.background-overlay-dark>.container-fluid,
.background-overlay-light>.container-fluid,
.background-overlay-grey>.container-fluid,
.background-overlay-grey-dark>.container-fluid,
.background-overlay-dark>.container-fluid,
#particles-js>.container-fluid,
.background-overlay-gradient-colored>.container-wide,
.background-overlay-gradient>.container-wide,
.background-overlay-gradient-dark>.container-wide,
.background-overlay>.container-wide,
.background-overlay-one>.container-wide,
.background-overlay-dark>.container-wide,
.background-overlay-light>.container-wide,
.background-overlay-grey>.container-wide,
.background-overlay-grey-dark>.container-wide,
.background-overlay-dark>.container-wide,
#particles-js>.container-wide {
    position: relative;
}

.background-overlay-dark .mbYTP_wrapper,
.background-overlay .mbYTP_wrapper {
    z-index: -1 !important;
}

.background-overlay-gradient:before {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.59) 0%, rgba(255, 255, 255, 0) 99%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.59)), color-stop(99%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.59) 0%, rgba(255, 255, 255, 0) 99%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0.59) 0%, rgba(255, 255, 255, 0) 99%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.59) 0%, rgba(255, 255, 255, 0) 99%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.59) 0%, rgba(255, 255, 255, 0) 99%);
    filter: unicode("progid: DXImageTransform.Microsoft.gradient( startColorstr='#96000000', endColorstr='#00ffffff', GradientType=0)");
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.8;
}

.background-overlay-gradient-dark:before {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(16, 16, 16, 0.99) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(16, 16, 16, 0.99) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(16, 16, 16, 0.99) 100%);
    filter: unicode("progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#fc101010', GradientType=0)");
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    z-index: 0;
}

.background-overlay-gradient-colored:before {
    background: #ba01ff;
    background: -moz-linear-gradient(top, #ba01ff 0%, #ff0092 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ba01ff), color-stop(100%, #ff0092));
    background: -webkit-linear-gradient(top, #ba01ff 0%, #ff0092 100%);
    background: -o-linear-gradient(top, #ba01ff 0%, #ff0092 100%);
    background: -ms-linear-gradient(top, #ba01ff 0%, #ff0092 100%);
    background: linear-gradient(to bottom, #ba01ff 0%, #ff0092 100%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.8;
}

.background-overlay:before {
    background: url("../images/overlay-pattern/overlay-pattern-1.png");
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.8;
    min-height: 2000px;
}

.background-overlay-one:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .3);
    content: " ";
    width: 100%;
    height: 100%;
    z-index: 0;
    min-height: 2000px;
}

.background-overlay-dark:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .8);
    content: " ";
    width: 100%;
    height: 100%;
    z-index: 0;
    min-height: 2000px;
}

.background-overlay-light:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
    content: " ";
    width: 100%;
    height: 100%;
    z-index: 0;
    min-height: 2000px;
}

.background-overlay-grey:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    content: " ";
    width: 100%;
    height: 100%;
    z-index: 0;
    min-height: 2000px;
}

.background-overlay-grey-dark:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0; 
    left: 0;
    background: rgba(84, 87, 95, 0.8);
    content: " ";
    width: 100%;
    height: 100%;
    z-index: 0;
    min-height: 2000px;
}

.background-colored {
    background-color: $color-theme;
}

#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    background: rgba(0, 0, 0, .6);
    z-index: 0;
}


/* ----------------------------------------------------------------
    MULTISCROLL
-----------------------------------------------------------------*/

#multiscroll .ms-left .ms-section {
    background-position: 100% center !important;
}

#multiscroll .ms-right .ms-section {
    background-position: 0 center !important;
}


/* ----------------------------------------------------------------
GO TO TOP BUTTON
-----------------------------------------------------------------*/

#goToTop {
    opacity: 0;
    position: fixed;
    text-align: center;
    line-height: 12px!important;
    right: 26px;
    bottom: 0;
    color: #fff;
    cursor: pointer;
    border-radius: 100px;
    z-index: 999;
    height: 40px;
    width: 40px;
    background-color: rgba(0, 0, 0, 0.25);
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity .3s, bottom .5s, box-shadow 0.3s cubic-bezier(.55, 0, .1, 1), background-color 0.1s linear;
    background-color: rgba(0, 0, 0, 0.25)!important;
    overflow: hidden;
    i {
        line-height: 39px!important;
        width: 39px!important;
        height: 39px!important;
        font-size: 16px!important;
        top: 0px!important;
        left: 0px!important;
        text-align: center!important;
        position: relative;
        z-index: 10;
        background-color: transparent!important;
        transform: translate(0, 0px);
        transition: transform 0.2s ease;
    }
    &:hover {
        transition: box-shadow 0.3s cubic-bezier(.55, 0, .1, 1), background-color 0.05s linear 0.25s;
        background-color: transparent!important;
        i {
            transform: translate(0, -39px)!important;
        }
    }
    &:after,
    &:before {
        display: block;
        content: ' ';
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: $color-theme;
        transform: scale(0);
        transition: all 0.3s cubic-bezier(.55, 0, .1, 1);
        border-radius: 100px;
    }
    &:before {
        background-color: rgba(255, 255, 255, 0.25);
        transform: scale(1);
        transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
        opacity: 0;
        z-index: 2;
    }
    &:hover:after {
        transform: scale(1);
    }
    &:active:before {
        opacity: 1;
    }
}


/* ----------------------------------------------------------------
Misc
-----------------------------------------------------------------*/

.show-grid[class*="col-lg-"] .show-grid-block {
    background-color: #eeeeee;
    border: 1px solid #ffffff;
    display: block;
    line-height: 40px;
    min-height: 40px;
    text-align: center;
}

.fontawesome-icon-list i {
    font-size: 18px;
    display: inline-block;
    font-size: 1.3em;
    margin-right: 5px;
    position: relative;
    text-align: center;
    top: 2px;
    width: 30px;
    padding: 16px 0px;
}

[class^="icon"] {
    display: inline-block;
}


/* ----------------------------------------------------------------------
Images
-------------------------------------------------------------------------*/

.img-thumbnail {
    border: 1px solid #eee;
    padding: 1px;
}


/* ----------------------------------------------------------------------
NEWS SECTIONS
-------------------------------------------------------------------------*/

.news-section-wrapper {
    clear: both;
    display: block;
    padding: 0 80px;
    position: relative;
}


/* ----------------------------------------------------------------------
NEWS MARKETING BOX
-------------------------------------------------------------------------*/

.marketing-box {
    background-color: #f1f1f1;
    border: 1px solid #eee;
    clear: both;
    color: #999;
    height: 100px;
    line-height: 100px;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
}


/* ----------------------------------------------------------------------
NEWS MARKETING BOX
-------------------------------------------------------------------------*/

.news-ticker {
    border-bottom: 1px solid #eee;
    position: relative;
}

.news-ticker-title {
    background-color: #f5f5f5;
    float: left;
    margin-right: 12px;
    height: 100%;
}

.news-ticker-title h4 {
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    padding: 20px 30px 17px;
}

.news-ticker-content {
    margin-bottom: 0;
    padding: 14px;
    width: 100%;
}

.news-ticker-content .owl-item::before {
    color: #d82731;
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    font-size: 10px;
    margin-left: -24px;
    position: absolute;
}


/* ----------------------------------------------------------------------
DEMO GRID
-------------------------------------------------------------------------*/

.grid-system-demo [class*="col-"] .grid-col-demo {
    background-color: #eee;
    display: block;
    line-height: 54px;
    min-height: 54px;
    text-align: center;
}

.grid-system-demo .row {
    padding-bottom: 42px;
}

.grid-system-demo-live .row {
    padding-bottom: 42px;
}


/* ----------------------------------------------------------------------
BOXED GRID
-------------------------------------------------------------------------*/

.boxed-grid .row {
    margin-right: -10px;
    margin-left: -10px;
}

.boxed-grid [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
}

.boxed-grid [class*="col-"] a {
    margin-bottom: 20px;
}

.boxed-grid a {
    overflow: hidden;
    display: block;
    position: relative;
}

.boxed-grid a img {
    width: 100%;
    height: auto;
    transition: all 0.6s ease;
}

.boxed-grid a:hover img {
    transform: scale(1.2);
}

.boxed-grid a .title {
    position: absolute;
    top: 40%;
    left: 30px;
    font-size: 28px;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
}


/* ----------------------------------------------------------------------
PAGE 404
-------------------------------------------------------------------------*/

.page-error-404 {
    color: #eee;
    display: block;
    font-size: 300px;
    font-weight: 800;
    line-height: 0.7;
}

body.device-xs .page-error-404 {
    font-size: 200px;
    line-height: 0.8;
    margin-bottom: 20px;
}

body.device-xxs .page-error-404 {
    font-size: 140px;
    line-height: 0.8;
    margin-bottom: 20px;
}


/* ----------------------------------------------------------------
Scrolldown icon animation
-----------------------------------------------------------------*/

.scrolldown-animation {
    animation: 1s ease-in-out 0s normal none infinite running scrolldown-keyframes;
    bottom: 40px;
    height: 40px;
    left: 50%;
    margin-left: -20px;
    position: absolute;
    width: 45px;
    z-index: 60;
}

@keyframes scrolldown-keyframes {
    0%,
    100% {
        transform: translateY(-8px);
    }
    50% {
        transform: translateY(0px);
    }
}

@keyframes scrolldown-keyframes {
    0%,
    100% {
        transform: translateY(-8px);
    }
    50% {
        transform: translateY(0px);
    }
}


/*animated background*/

.animated-background {
    animation: 120s linear 0s normal none infinite running animatedBackground;
    background-repeat: repeat-x;
    background-size: cover;
}

@keyframes animatedBackground {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -3000px 0;
    }
}

@keyframes animatedBackground {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -3000px 0;
    }
}


/* ----------------------------------------------------------------
Image Block
-----------------------------------------------------------------*/

.image-block {
    background-color: #f4f4f4;
}

.image-block .col-lg-6 {
    padding: 60px;
}

.image-block-content {
    margin-bottom: 30px;
    padding: 0 50px;
}

.image-block-content .feature-icon {
    border-radius: 100%;
    color: #fff;
    display: inline-block;
    height: 60px;
    line-height: 60px;
    text-align: center;
    width: 60px;
    background-color: #eee;
}

.image-block-content .feature-icon i {
    font-size: 28px;
    margin-top: 16px;
}

.image-block-content .feature-content {
    padding-left: 85px;
}

.image-block-content .feature-content h3 {
    margin-bottom: 0;
}

.image-absolute {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 426px;
    height: 556px;
}


/*hero headings*/

.heading-fancy-border {
    border: 15px solid #111;
    margin: 0 10%;
    padding: 60px 0px;
}

.text-light .heading-fancy-border {
    border-color: #fff !important;
}


/*hero heading 2*/

.hero-heading-2 {
    padding: 20px;
    background-color: #111;
    border-radius: 8px;
}

.hero-heading-2>i {
    font-size: 160px;
    opacity: 0.1;
    position: absolute;
    right: 23px;
    top: 2px;
}


/*hero heading 3*/

.hero-heading-3 {
    background-color: #fff;
    border: 36px solid #fff;
    box-shadow: 0 0 0 1px #111 inset;
    color: #111;
    opacity: 0.7;
    padding: 72px;
    width: auto;
    margin: 40px;
    text-align: center;
}

.hero-heading-3 h2,
.hero-heading-3 h3 {
    font-family: 'Josefin Sans', sans-serif;
    text-transform: uppercase;
    font-size: 92px;
    text-align: center;
    margin: 0;
    line-height: 72px;
}


/*working-hours list*/

.working-hours ul {
    padding: 0;
}

.working-hours li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: inline-block;
    padding: 10px 0;
    width: 100%;
}

.working-hours li::before {
    content: "\f017";
    font-family: "Font Awesome 5 Free";
    margin-right: 15px;
}

.working-hours li span {
    float: right;
}

.working-hours p {
    font-size: 13px;
    color: #111;
}


/*price menu list*/

.price-menu-list {
    padding: 0;
    list-style: none;
}

.price-menu-list li {
    margin-bottom: 40px;
}

.price-menu-list li>h2 {
    margin-bottom: 0;
}

.price-menu-list li>p {
    margin-bottom: 8px;
    opacity: 0.6;
}

.price-menu-list li>img {
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 100px;
    height: 100px;
    float: left;
}


/* ----------------------------------------------------------------------
   Page loader
-------------------------------------------------------------------------*/


body:not(.no-page-loader) #wrapper,
.animsition-overlay {
    opacity: 0; 
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animsition-overlay,
.animsition-overlay-slide {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #fff;
}
 /* loading option */
.animsition-loading,
.animsition-loading:after {
  width: 32px;
  height: 32px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -16px;
  margin-left: -16px;
  border-radius: 50%;
  z-index: 100;
}

.animsition-loading {
      background-color: $color-white;
    width: 100%;
    height: 100%;

}



/* loading option */
/*
.animsition-loading {
    background-color: $color-white;
    z-index: 9999 !important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .loader {
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: -16px;
        border-radius: 50%;
        z-index: 100;
    }
}*/

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-in {
    -webkit-animation-name: fade-in;
    animation-name: fade-in;
}

@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fade-out {
    -webkit-animation-name: fade-out;
    animation-name: fade-out;
}

.ball-grid-pulse>div,
.square-spin>div,
.ball-rotate>div,
.ball-rotate>div:after,
.ball-rotate>div:before,
.cube-transition>div,
.ball-zig-zag>div,
.ball-triangle-path>div,
.line-scale>div,
.ball-scale-multiple>div,
.ball-pulse-sync>div,
.ball-beat>div,
.line-scale-pulse-out-rapid>div,
.ball-scale-ripple>div,
.ball-spin-fade-loader>div,
.line-spin-fade-loader>div,
.pacman>div:nth-child(3),
.pacman>div:nth-child(4),
.pacman>div:nth-child(5),
.pacman>div:nth-child(6),
.ball-grid-beat>div {
    background-color: $color-theme;
}

.ball-scale-ripple-multiple>div {
    border: 2px solid $color-theme;
}

.pacman>div:first-of-type,
.pacman>div:nth-child(2) {
    border-color: $color-theme transparent $color-theme $color-theme;
}


/* ----------------------------------------------------------------------
Responsive utilities
-------------------------------------------------------------------------*/

.responsive-utilities th small {
    color: #999;
    display: block;
    font-weight: 400;
}

.responsive-utilities td.is-visible {
    background-color: #dff0d8 !important;
    color: #468847;
}


/* ----------------------------------------------------------------
Bootstrap row colums fixes
-----------------------------------------------------------------*/

.col-lg-2-5 {
    width: 20%;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
}
/*

.row .col-lg-6:nth-child(2) {
    clear: right;
}

.row .col-4:nth-child(3n+4),
.row .col-3:nth-child(4n+5),
.row .col-2:nth-child(6n+7),
.row .col-1:nth-child(12n+13),
.row .col-lg-4:nth-child(3n+4) {
    clear: right;
}

@media(min-width:768px) {
    .row .col-6:nth-child(2n+3),
    .row .col-4:nth-child(3n+4),
    .row .col-3:nth-child(4n+5),
    .row .col-2:nth-child(6n+7),
    .row .col-1:nth-child(12n+13) {
        clear: none
    }
}

@media(min-width:992px) {
    .row .col-md-6:nth-child(2n+3),
    .row .col-md-3:nth-child(4n+5),
    .row .col-md-2:nth-child(6n+7),
    .row .col-md-1:nth-child(12n+13) {
        clear: left;
    }
    .row .col-lg-4:nth-child(3n+4) {
        clear: right;
    }
}

@media(min-width:1200px) {
    .row .col-lg-2:nth-child(6n+7),
    .row .col-lg-1:nth-child(12n+13) {
        clear: right;
    }
    .row .col-lg-6:nth-child(2n+3),
    .row .col-lg-4:nth-child(3n+4),
    .row .col-lg-3:nth-child(4n+5),
    .row .col-lg-2:nth-child(6n+7),
    .row .col-lg-1:nth-child(12n+13) {
        clear: left
    }
}

@media (min-width: 480px) and (max-width: 991px) {
    .row .col-lg-6:nth-child(2n+3),
    .row .col-lg-4:nth-child(2n+3),
    .row .col-lg-3:nth-child(2n+4),
    .row .col-lg-6:nth-child(2n+3),
    .row .col-lg-4:nth-child(3n+4),
    .row .col-lg-3:nth-child(4n+5),
    .row .col-lg-2:nth-child(6n+7),
    .row .col-lg-1:nth-child(12n+13) {
        clear: left;
    }
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4 {
        clear: right !important;
        float: left;
        margin-right: 0;
        width: 49.99%;
    }
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-19,
    .col-lg-11,
    .col-lg-12 {
        width: 100%;
    }
    .col-lg-6 .col-lg-6 {
        clear: right;
        float: left;
        margin-right: 0;
        width: 50%;
    }
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-19,
    .col-md-11,
    .col-md-12 {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-19,
    .col-md-11,
    .col-md-12 {
        width: 100%;
    }
}
*/


/* ----------------------------------------------------------------
Creative Profile Page
-----------------------------------------------------------------*/

.profile-content {
    width: 100%;
    padding: 0;
    position: relative;
    .profile-image {
        background-position: 50% 20%;
        background-repeat: repeat-y;
        background-size: cover;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 66.6666%;
        z-index: 2;
        display: table;
        .profile-name {
            display: table-cell;
            height: 100%;
            padding: 30px;
            position: relative;
            vertical-align: bottom;
            width: 100%;
            h3 {
                color: #fff;
                font-size: 130px;
                opacity: 0.7;
                text-shadow: 0 22px 25px rgba(0, 0, 0, 0.13);
                line-height: 120px;
            }
        }
    }
    .profile-bio {
        float: right;
        height: auto;
        margin: 50px;
        overflow: hidden;
        padding: 0 0 0 100px;
        position: relative;
        right: 0;
        top: 0;
        width: 33.3333%;
        z-index: 1;
        .profile-bio-footer {
            padding: 20px 0 0;
            width: 100%;
        }
    }
}


/* ----------------------------------------------------------------
Text-Rotator
-----------------------------------------------------------------*/

.morphext>.animated {
    display: inline-block;
    opacity: 1;
}


/* ----------------------------------------------------------------
Avatar image
-----------------------------------------------------------------*/

img.avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}

.ohidden {
    position: relative;
    overflow: hidden !important;
}


/* ----------------------------------------------------------------
Play Button
-----------------------------------------------------------------*/

.play-button {
    position: relative;
    width: 90px;
    height: 90px;
    display: inline-block;
    border-width: 7px;
    border-style: solid;
    border-color: $color-white;
    border-radius: 50%;
    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0s;
    >i {
        font-size: 22px;
        color: $color-white;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: 2px;
        transform: translateY(-50%) translateX(-50%);
    }
    &.dark {
        border-color: $dark;
        >i {
            color: $dark;
        }
    }
    &:hover {
        transform: scale(1.15);
        transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        /* > i {
        font-size: 18px;
        color: $color-white;
    }
    &.dark {
        border-color: $dark;
        > i {
            color: $dark;
        }
    }*/
    }
}

// Lines button
.lines-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    cursor: pointer;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    transition: 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 16px;
    background: transparent;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
    >* {
        display: block;
    }
    &:hover,
    &:focus {
        outline: none;
    }
    &::-moz-focus-inner {
        border: 0;
    }
}

.lines {
    display: inline-block;
    height: 2px;
    width: 16px;
    border-radius: 4px;
    transition: 0.3s;
    position: relative;
    top: -4px;
    &::before,
    &::after {
        display: inline-block;
        height: 2px;
        width: 16px;
        border-radius: 4px;
        transition: 0.3s;
        content: '';
        position: absolute;
        left: 0;
        -webkit-transform-origin: 2.85714px center;
        transform-origin: 2.85714px center;
        width: 100%;
    }
    &::before {
        top: 5px;
    }
    &::after {
        top: -5px;
    }
    &,
    &:before,
    &:after {
        background-color: #111;
    }
}

.toggle-active .lines {
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8);
}

.x {
    width: auto;
}

.x.toggle-active .lines {
    background: transparent !important;
}

.x.toggle-active .lines::before,
.x.toggle-active .lines::after {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    top: 0;
    width: 20px;
}

.x.toggle-active .lines::before {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
}

.x.toggle-active .lines::after {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
}

/*a.toggle-item:hover,
a.toggle-item:focus,
a.toggle-item:active {
    color: $color-theme;
}*/ 

.toggle-item {
    i {
        position: relative;
        &:nth-of-type(2) {
            visibility: hidden;
            position: absolute;
        }
    }
    &.toggle-active {
        i {
            &:nth-of-type(2) {
                visibility: visible;
                position: relative;
            }
            &:nth-of-type(1) {
                visibility: hidden;
                position: absolute;
            }
        }
    }
}

.image-hover-zoom {
    position: relative;
    overflow: hidden;
    cursor: url('../images/expand.png'), default;
    img {
        transition: all .4s ease;
        opacity: 1
    }
    /*hover state*/
    &:hover {
        img {
            opacity: .3;
        }
    }
}


/* ----------------------------------------------------------------
    VM PLAYER (VIMEO PLAYER)
-----------------------------------------------------------------*/
.vimeo_player_wrapper {
    z-index: -1 !important;
}

.vimeo_player_wrapper ~ iframe {
    display: none;
}

/* ----------------------------------------------------------------
    YT PLAYER (YOUTUBE PLAYER)
-----------------------------------------------------------------*/

#youtube-background-controls {
    background: none;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 60px;
    position: relative;
    width: 60px;
}

#youtube-background-controls .fa {
    font-size: 23px;
    line-height: 40px;
}

#youtube-background-controls .fa-pause,
#youtube-background-controls .fa-play {
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    opacity: 0;
    position: absolute;
    left: 19px;
    top: 9px;
}

#youtube-background-controls .fa-play {
    left: 22px;
}

#youtube-background-controls .fa-pause {
    opacity: 1;
}

#youtube-background-controls.video-is-playing .fa-play {
    opacity: 1;
}

#youtube-background-controls.video-is-playing .fa-pause {
    opacity: 0;
}

.block-shadow {
    -moz-box-shadow: 0 25px 75px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 25px 75px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 25px 75px 0 rgba(0, 0, 0, 0.2);
    margin-left: -100px;
}

.portfolio-item .new-badge {
    // background-image: url("../preview/images/new-badge.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    height: 61px;
    position: absolute;
    right: 12px;
    top: 3px;
    width: 55px;
    z-index: 100;
}


/* ----------------------------------------------------------------
    Hover 3d
-----------------------------------------------------------------*/

.hover-3d {
    /*    .portfolio-description,
    .grid-description {
  transform: translateZ(0px);
        a {
            h3 {
                font-size: 32px;
                line-height: 34px;
            }
        }
        p {
            font-size: 16px;
        }
    }*/
    &:hover {
        .grid-item-wrap,
        .portfolio-item-wrap {
            box-shadow: 0 10px 30px rgba(0, 0, 0, .4);
            .portfolio-description,
            .grid-description {
                //  transform: translateZ(30px);
            }
        }
    }
    &.hover-in {
        transition: transform .2s ease-out;
    }
    &.hover-out {
        transition: transform .2s ease-in;
    }
}


/* ----------------------------------------------------------------
    Bar Rating
-----------------------------------------------------------------*/

.bar-rating {
    height: 23px;
    float: left;
    color: #FFC300;
}


/* ----------------------------------------------------------------
    Bar Rating
-----------------------------------------------------------------*/

.image-left-offset { 
    margin-left: -15px;
    img {
        width: 100%;
        max-width: none;
        z-index: 1;
        position: relative;
        display: block;
    }
}

.image-right-offset {
    margin-right: -15px;
    img {
        width: 100%;
        max-width: none;
        z-index: 1;
        position: relative;
        display: block;
    }
}




/*Fixes*/

#header.dark .heading-creative {
    color: $light;
}