.icon-preview {
  display: table;
  padding: 10px 6px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
  .icon-holder {
    display: table-cell;
    vertical-align: middle;
    padding: 0;
    text-align: left;
    padding-right: 10px;
    width: 50px;
    i, > div {
      line-height: 0;
      vertical-align: middle;
      font-size: 2rem !important;
        color: #333439;
    }
  }
  .icon-class {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    padding: 0;
    font-size: .85rem;
    font-weight: 400;
      color: #7b7e8a;
  }

  &:hover {
    box-shadow: 0 1px 15px 1px rgba(69,65,78,.08);
  }

}