
// DELETE VARIABLES

/* ----------------------------------------------------------------
Lables & Badgets
-----------------------------------------------------------------*/

.main-menu .badge,
.sidebar-menu .badge {
    padding: 1px 3px;
    margin: 0 3px;
}
.badge {
    
}

.badge-primary {
        background-color: $color-theme;   
    }