

$classes : (
	"&.item-white":						$color-white,
	"&.item-book-blue-dark":			$color-book-blue-dark,
	"&.item-book-blue-light":			$color-book-blue-light,
	"&.item-book-grey":					$dark-light,
	"&.item-strategie, &.item-1": 		$color-1-strategie,
	"&.item-financier , &.item-2": 		$color-2-financier,
	"&.item-operationnel, &.item-3": 	$color-3-operationnel,
	"&.item-securite , &.item-4": 		$color-4-securite,
	"&.item-crise , &.item-5": 			$color-5-crise,
	"&.item-conformite , &.item-6": 	$color-6-conformite,
	"&.item-rh , &.item-7": 			$color-7-rh
);


.item-bg {	
	@each $i,$el in $classes { 
		#{$i} {
			background: $el;
		}
	}
} 
	
.item-color {
	@each $i,$el in $classes { 
		#{$i} {
			color: $el;
		}
	}
}

.item-border {
	@each $i,$el in $classes { 
		#{$i} {
			border-color: $el;
		}
	}
}


@each $i,$el in $classes { 
	#{$i} {
		.progress-bar {
			background: $el;
		}
	}
}

a.item_sous_navigation {
	color: #666666;
	text-decoration: none !important;
	&.active {

	}
}